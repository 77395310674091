<template>
  <vams-collapse v-if="techRemarks && techRemarks.length > 0" :ref="`collapse-${jobKey}`" title="Tech Remarks">
    <div v-for="(remark, index) of techRemarks" :key="index">
      <div class="pb-3">
        <b-row no-gutters class="font-weight-bold">
          <employee-display :employee="{ name: remark.employeeName }" :show-email="false" />
          <span class="ml-3">{{ formatDate(remark.dateUpdate) }}</span>
        </b-row>
        {{ remark.techRemark }}
      </div>
    </div>
  </vams-collapse>
</template>

<script>
// components
import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
// vuex
import { mapGetters } from 'vuex';
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
// other
import moment from 'moment';

export default {
  name: 'TechRemarksComponent',
  components: {
    'vams-collapse': CollapseComponent,
    'employee-display': EmployeeDisplayComponent
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NA: 'N/A'
    };
  },
  computed: {
    ...mapGetters([ServiceOrderGetters.GET_TECH_REMARKS]),
    techRemarks() {
      return this[ServiceOrderGetters.GET_TECH_REMARKS](this.jobKey);
    }
  },
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('MM/DD/YYYY hh:mm a');
    }
  }
};
</script>
